<template>
    <div
        class="common-form content-bg"
        :class="{'page-view': query.pageType == 'view'}"
    >
        <div :class="{'common-form-layout': query.pageType !== 'view' }">
            <global-page-back
                :detailPageTitle="query.pageBackTitle || '职工档案'"
                @handlerGlobalType="handlerGlobalType"
            >
                <div
                    v-if="query.pageType === 'view'"
                    slot="globalPageBackTabs"
                    style="display:flex;align-items:center;"
                >
                    <div style="width:1px; height:24px;background-color:#D4D6D9;margin: 0 28px 0 58px;"></div>
                    <el-button
                        type="primary"
                        @click="firstExport()"
                        >导出pdf</el-button>
                </div>
                <div
                    v-else
                    slot="globalPageBackTabs"
                    class="tab-wrapper">
                        <div
                            v-for="(item, index) of tabs"
                            :key="index"
                            :class="['tab-item',{current: item.sel}]"
                            @click="changeTabs(item)"
                        >
                        {{ item.title }}
                        <span v-show="item.sel" class="sel-line"></span>
                        </div>
                </div>
            </global-page-back>

            <div class="common-form-wrapper" v-loading="loading">
                    <form-anchor
                        v-if="query.pageType === 'add' || query.pageType === 'edit'"
                        :anchorList="anchorList"
                        @handlerFormScroll="handlerFormScroll"
                    >
                    </form-anchor>
                    <!-- 新增或编辑=》基础信息 -->
                    <summary-teacher-form
                        v-if="query.pageType === 'add' || query.pageType === 'edit'"
                        v-show="tabs[0].sel"
                        ref="summaryTeacherForm"
                        :ruleForm="ruleForm"
                        :rules="rules"
                        :options="options"
                        :options2="options2"
                        :options3="options3"
                        :subjects="subjects"
                        :postList="postList"
                        :newRoleList="newRoleList"
                        :userInput="userInput"
                        :disabledPassword="disabledPassword"
                        :query="query"
                        :archivesCompleteness="archivesCompleteness"
                        :detailFormShow="detailFormShow"
                        :headImgInfo="headImgInfo"
                        @handleAvatarSuccess="handleAvatarSuccess"
                        @changePhone="changePhone"
                        @handlePhoneBlur="handlePhoneBlur"
                        @postName="postName"
                        @handleLoginNameBlur="handleLoginNameBlur"
                        @handleUserBlur="handleUserBlur"
                        @addNewSector="addNewSector"
                        @showStowDetailForm="showStowDetailForm"
                        @synchronous="synchronous"
                        @controlSynchronous="controlSynchronous"
                        @setHeadImgInfo="setHeadImgInfo"
                    >
                    </summary-teacher-form>
                    <!-- 查看--信息 -->
                    <summary-teacher-view
                        v-else
                        :loading="loading"
                        :ruleForm="ruleForm"
                        :rules="rules"
                        :segmentIds="summaryTeacherViewArr"
                        :basicInfoViewFlag="basicInfoViewFlag"
                        :moreInfoViewFlag="moreInfoViewFlag"
                        :showRoleList="showRoleList"
                        :subJectList="subJectList"
                        :showPostList="showPostList"
                        :oriBasicForm="oriBasicForm"
                        :query="query"
                        @desensitizeGet="desensitizeGet"
                        @getTemplateList="getTemplateList"
                    >
                    </summary-teacher-view>
                    <!-- 新增或编辑=》更多信息 -->
                    <summary-teacher-more-form
                        v-if="tabs[1].sel && (query.pageType === 'add' || query.pageType === 'edit')"
                        v-show="tabs[1].sel"
                        :oriBasicForm="oriBasicForm"
                        :query="query"
                        :ruleForm="ruleForm"
                        :templateList="templateList"
                        :selectedTemplate="selectedTemplate"
                        @getTeacherBaseInfo="getTeacherBaseInfo"
                        @getFormRules="getFormRules"
                    >
                    </summary-teacher-more-form>
            </div>
            <form-submit-btn
                v-if="query.pageType === 'add' || query.pageType === 'edit'"
            >
                <div slot="formSubmitBtn">
                    <el-button
                        v-if="query.pageType === 'add'"
                        type="primary"
                        @click="submitForm('ruleForm')"
                        >保存</el-button
                    >
                    <el-button
                        v-if="query.pageType === 'edit'"
                        type="primary"
                        @click="submitForm('ruleForm')"
                        >保存</el-button
                    >
                    <el-button
                        type="enquiry"
                        style="margin-left: 20px"
                        @click="back"
                        >取消</el-button
                    >
                </div>
            </form-submit-btn>
        </div>

        <el-dialog
            title="导出教师档案"
            :visible.sync="exportDialog"
            width="640px"
            :modal-append-to-body="false"
            center
        >
            <div class="exportDialog">
                <div class="exportDialog-title">
                    <div class="exportDialog-text">自定义导出内容</div>
                    <div
                        class="exportDialog-reset"
                        @click="resetExport"
                    >
                        重置
                    </div>
                </div>
                <div class="exportDialog-content">
                    <div class="exportDialog-content-title">
                        <div class="exportDialog-content-sort">排序</div>
                        <div class="exportDialog-content-name">模块名称</div>
                    </div>
                    <div class="exportDialog-content-title-sort" v-if="basicInfoViewFlag">
                        <div class="exportDialog-content-sort-title">1</div>
                        <div class="exportDialog-content-name-text">
                            基础信息
                        </div>
                    </div>
                    <draggable :list="sortPdfList">
                        <transition-group>
                            <div
                                v-for="(item, index) in sortPdfList"
                                :key="item.id"
                                class="exportDialog-list"
                            >
                                <div class="exportDialog-sort">
                                    {{ basicInfoViewFlag ? index + 2 : index + 1 }}
                                </div>
                                <div class="exportDialog-name">
                                    <div class="exportDialog-name-info">
                                        {{ item.archivesName }}
                                    </div>
                                    <div class="exportDialog-icon">
                                        <img
                                            :src="moveUrl"
                                            class="move-icon"
                                            alt="icon"
                                        />
                                        <img
                                            :src="delUrl"
                                            class="del-icon"
                                            @click="delDra(item)"
                                            alt="icon"
                                        />
                                    </div>
                                </div>
                            </div>
                        </transition-group>
                    </draggable>
                </div>
                <div class="exportDialog-info">
                    <img
                        :src="infoUrl"
                        class="dialog-info-icon"
                        alt="icon"
                    />
                    <div class="dialog-info-text">
                        导出的pdf会根据自定义排序的顺序进行展示
                    </div>
                </div>
                <div class="dialog-footer">
                    <div
                        class="dialog-submit"
                        @click="previewPdf"
                    >
                        导出pdf
                    </div>
                    <div
                        class="dialog-cancel"
                        @click="exportDialog = false"
                    >
                        取消
                    </div>
                </div>
            </div>
        </el-dialog>
        <teacherExportDialog
            :basicInfoViewFlag="basicInfoViewFlag"
            :previewPdfList="previewPdfList"
            :exportTeacherInfoDialog="exportTeacherInfoDialog"
            @closeTeacherDialog="closeTeacherDialog"
        ></teacherExportDialog>
    </div>
</template>

<script>
import {MTreeSelect, debounce} from "common-local";
import GlobalPageBack from "./Sub/GlobalPageBack/index.vue";
import FormAnchor from "./Sub/FormAnchor/index.vue";
import PageBackTabs from "./Sub/PageBackTabs/index.vue";
import SummaryTeacherForm from "./SummaryTeacherInfo/SummaryTeacherForm.vue";
import SummaryTeacherView from './SummaryTeacherInfo/SummaryTeacherView.vue';
import SummaryTeacherMoreForm from './SummaryTeacherInfo/SummaryTeacherMoreForm.vue'
import FormSubmitBtn from "./Sub/FormSubmitBtn/index.vue";

import { baseInfo, moreInfo } from "@/assets/data/SummaryTeacher/anchorList.js";

import {
    formRules,
    changeRules,
} from "@/assets/data/SummaryTeacher/formRules.js";
import {getNewRules} from "@/operations/summaryTeacher.js";
import formRequiredFields from "@/libs/formRule.js";
import {listToTree} from "@/libs/utils.js";
import draggable from "vuedraggable";
import Vue from "vue";
import ElSelectTree from "el-select-tree";
import teacherExportDialog from "@/components/scrollWrapper/SummaryTeacherInfo/SunmmaryTeacherInfoDialog";
Vue.use(ElSelectTree);
import { mapState } from 'vuex';
import { jsonClone } from '../../assets/data/formDesign/utils';


export default {
    name: "SummaryTeacherInfo",
    components: {
        FormAnchor,
        GlobalPageBack,
        PageBackTabs,
        SummaryTeacherForm,
        SummaryTeacherMoreForm,
        SummaryTeacherView,
        FormSubmitBtn,
        MTreeSelect,
        draggable,
        teacherExportDialog
    },
    props: ["query"],
    data() {
        return {
            baseInfo,
            moreInfo,
            formRules,
            changeRules,
            userInput: true,
            loading: false,
            ruleForm: {
                teacherName: "",
                remark: "",
                id: "",
                schoolId: "",
                phone: "",
                onlyCode: "",
                status: 1,
                sex: "1",
                classType: 1,
                post: "",
                postName: "",
                orgList: [],
                headImg: "",
                isHeadmaster: "2",
                isSubjectTeacher: "2",
                subjectClassList: [],
                manageClassList: [],
                oldManageClassList: [],
                viewList: [],
                subjectName: "",
                subject: "",
                loginName: "",
                password: "",
                roleIdList: [],
                entryTime: "",
                powerOrganList: [],
                userName: "",
                powerList: [],
                serialNumber: "",
                consumNumber: "",
                carNumbers:[],
                schoolNum: "",
            },
            rules: {
                teacherName: [
                    {
                        required: true,
                        message: "请输入姓名",
                        trigger: ["change", "blur"],
                    },
                ],
                phone: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (
                                !/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(
                                    value,
                                )
                            ) {
                                callback("手机号不正确");
                            } else {
                                callback();
                            }
                        },
                        trigger: "blur",
                    },
                ],
                onlyCode: [
                    {
                        required: true,
                        message: "请输入工号",
                        trigger: "blur",
                    },
                ],
                sex: [
                    {
                        required: true,
                        message: "请选择性别",
                        trigger: "change",
                    },
                ],
                isSubjectTeacher: [
                    {
                        required: true,
                        message: "请选择是否是任课教师",
                        trigger: "change",
                    },
                ],
                isHeadmaster: [
                    {
                        required: true,
                        message: "请选择是否是班主任",
                        trigger: "change",
                    },
                ],
                orgList: [
                    {
                        required: true,
                        message: "请选择任职部门",
                        trigger: ["change", "blur"],
                    },
                ],
                subjectClassList: [
                    {
                        required: false,
                        message: "请选择任教班级",
                        trigger: "blur",
                    },
                ],
                subject: [
                    {
                        required: true,
                        message: "请选择任教科目",
                        trigger: "blur",
                    },
                ],
                manageClassList: [
                    {
                        required: true,
                        message: "请选择负责班级",
                        trigger: "blur",
                    },
                ],
                loginName: [
                    {
                        required: true,
                        message: "请输入登录手机号",
                        trigger: "blur",
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (!value) return;
                            let newValue = value.replace(
                                /[`¥~!@#$%^&*\+ =<>?"{}|, \/ ;' \\ [ \] ·~！@#￥%……&*\+ ={}|《》？：“”【】、；‘’，。、_]/g,
                                "",
                            );
                            if (newValue !== value) {
                                callback("禁止输入特殊符号");
                            } else {
                                callback();
                            }
                        },
                    },
                ],
                password: [
                    {
                        required: true,
                        message: "请输入登录密码",
                        trigger: "blur",
                    },
                    {
                        pattern:
                            /(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[\W_]).{8,}/,
                        message:
                            "必须包含大小写字母，特殊符号和数字且长度大于8位!",
                    },
                ],
                userName: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
            },
            organList: [],
            options: [],
            allOrgList: [],
            permissionOrgList: [],
            options2: [],
            options3: [],
            subjects: [],
            postList: [],
            showPostList: [],
            oriBasicForm: {},
            detailFormShow: false,
            roleList: [],
            disabledPassword: false,
            teacherRoleList: [],
            subJectList: [],
            exportDialog: false,
            exportForm: {
                archivesId: "",
                peopleId: "",
                schoolId: "",
            },
            newRoleList: [], //角色组的数据
            showRoleList: [], // 详情页面回显角色组数据
            sortPdfList: [], //pdf排序
            initSortList: [], //初始化pdf排序数据
            previewPdfList: {
                schoolId: "",
                peopleId: "",
                archivesTypeIds: [],
            }, //预览pdf数据传参
            exportTeacherInfoDialog: false,
            archivesCompleteness: 0,
            teacherId: "",
            controlsynchronousStatus: false,
            tabs: [
                {
                    id: 'baseInfo',
                    title: '基础信息',
                    hasAuth: true,
                    sel: true
                },
                {
                    id: 'moreInfo',
                    title: '更多信息',
                    hasAuth: false,
                    sel: false
                }
            ],
            selectedTemplate: -1,
            templateList: [],
            anchorList: [],
            headImgInfo: {
                auditState:'2',
                tip:'照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰照片清晰度不高，人像不清晰'
            },
            summaryTeacherViewArr: [], // 权限细分--可查看的档案模块的id集合
            summaryTeacherEditArr: [], // 权限细分--可编辑的档案模块的id集合
            basicInfoViewFlag: false, // 基础信息是否可查看
            moreInfoViewFlag: false, // 更多信息是否可查看
            viewBasicId: 'c0375dd06da44be79766ccf79dba9050', // 基础信息查看权限id
            viewMoreId: 'c3c23aee199e400eac74473d0eadff86', // 更多信息查看权限id
            basicInfoEditFlag: false, // 基础信息是否可编辑
            moreInfoEditFlag: false, // 更多信息是否可编辑
            editBasicId: '34b6ee9902b54983807b9be416ab6ebe', // 基础信息编辑权限id
            editMoreId: '9f941adc1f3b4f72a46edf53c61a34a6', // 更多信息编辑权限id
        };
    },
    filters: {},
    computed: {
        ...mapState({
            schoolId: state => state.schoolId,
            segmentPermList: state => state.permission.segmentPermList,
            segmentMenuList: state => state.permission.segmentMenuList,
        }),
        triangleUp() {
            return require("@/assets/images/stuInfoTemplate/triangle-up.png");
        },
        triangleDown() {
            return require("@/assets/images/stuInfoTemplate/triangle-down.png");
        },
        delUrl() {
            return require("@/assets/images/school-info-del.png");
        },
        infoUrl() {
            return require("@/assets/images/school-info-icon.png");
        },
        moveActiveUrl() {
            return require("@/assets/images/school-info-move.png");
        },
        moveUrl() {
            return require("@/assets/images/school-info-moveN.png");
        },
        archivesCompletenessUrl() {
            return require("@/assets/images/archivesCompleteness.png");
        },
    },
    async created() {
        await this.init();
        // 判断是否为编辑，编辑情况下获取真实手机号回填
        if (
            this.query.pageType === "edit" &&
            this.query.row.phone.indexOf("*") !== -1
        ) {
            this.desensitizeGet();
        }
        if (this.draggableList) {
            this.draggableRestList = JSON.parse(
                JSON.stringify(this.draggableList),
            );
        }
    },
    methods: {
        /**
         * @Description: 获取职工档案权限细分的相关信息
         * @DoWhat: 
         * @UseScenarios: 
         * @Attention: 
         * @Author: 王妙田
         * @Date: 2024-08-29 17:28:53
         */        
        getSegmentInfo() {
            // 查看则根据职工档案查看权限的id：03040109，在权限细分的数据中找到对应的权限
            const summaryTeacherViewId = '0304010109';
            // 编辑则根据职工档案编辑权限的id：03040106，在权限细分的数据中找到对应的权限
            const summaryTeacherEditId = '0304010106';
            let summaryTeacherMenu
            if(this.query.pageType === 'view') {
                // 如果是查看模式，则只获取查看权限
                summaryTeacherMenu = this.segmentPermList.find(item => item.menuId === summaryTeacherViewId);
                if(summaryTeacherMenu && summaryTeacherMenu.permSegment == 1) {
                    this.segmentMenuList.forEach(item => {
                        if(item.menuId === summaryTeacherViewId) {
                            this.summaryTeacherViewArr.push(item.segmentMenuId);
                        }
                        // 查看：基础信息、更多信息的查看权限
                        this.basicInfoViewFlag = this.summaryTeacherViewArr.includes(this.viewBasicId);
                        this.moreInfoViewFlag = this.summaryTeacherViewArr.includes(this.viewMoreId);
                    })
                } else {
                    // 如果职工档案菜单没有开启权限细分，则拥有所有查看权限
                    this.summaryTeacherViewArr.push('all');
                    this.basicInfoViewFlag = true;
                    this.moreInfoViewFlag = true;
                }
            } else if (this.query.pageType === 'edit') {
                // 编辑模式下，根据权限细分的数据判断是否拥有编辑基础信息、更多信息的权限
                summaryTeacherMenu = this.segmentPermList.find(item => item.menuId === summaryTeacherEditId);
                if(summaryTeacherMenu && summaryTeacherMenu.permSegment == 1) {
                    this.segmentMenuList.forEach(item => {
                        if(item.menuId === summaryTeacherEditId) {
                            this.summaryTeacherEditArr.push(item.segmentMenuId);
                        }
                        // 编辑：基础信息、更多信息的编辑权限
                        this.basicInfoEditFlag = this.summaryTeacherEditArr.includes(this.editBasicId);
                        this.moreInfoEditFlag = this.summaryTeacherEditArr.includes(this.editMoreId);
                    })
                } else {
                    // 如果职工档案菜单没有开启权限细分，则拥有所有编辑权限
                    this.summaryTeacherEditArr.push('all');
                    this.basicInfoEditFlag = true;
                    this.moreInfoEditFlag = true;
                }

                console.log(666,this.summaryTeacherEditArr, this.basicInfoEditFlag, this.moreInfoEditFlag);
                this.tabs[0].hasAuth = this.basicInfoEditFlag;
                this.tabs[1].hasAuth = this.moreInfoEditFlag;
            }
            
        },
        /**
         * @Description: 同步教师姓名和用户名
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-17 10:11:08
         */
         synchronous(value){
            if(!this.controlsynchronousStatus){
                this.ruleForm.userName = this.ruleForm.teacherName
            }
         },
        /**
         * @Description: 控制同步教师姓名和用户名
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 陈金伟
         * @Date: 2023-07-17 10:22:46
         */
         controlSynchronous(){
            this.controlsynchronousStatus = true;
         },
        // 焦点聚焦是触发
        handleUserBlur() {
            this.userInput = false;
        },
        /**
         * @Descripttion: 首次点击导出pdf，打开排序的弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-14 08:29:03
         */
        firstExport() {
            this.exportDialog = true;
            this.resetExport();
        },
        async getTeacherBaseInfo() {
            let str = this.teacherId ? this.teacherId : this.query.row.id;
            let r = await this._fet(`/school/schoolTeacher/getTeacherInfo/${str}`)
            if(r.data.code === "200") {
                const data = r.data.data;
                this.ruleForm.archivesCompleteness = data.archivesCompleteness;
            }
        },
        /**
         * @Descripttion: 获取教师档案更多模板数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-06 09:20:05
         * @param {*} value
         */
        getTemplateList(value) {
            console.log('导出的模板数据---------', value);
            this.initSortList = JSON.parse(JSON.stringify(value));
            this.sortPdfList = JSON.parse(JSON.stringify(value));
        },
        /**
         * @Descripttion: 删除导出列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2022-12-29 15:25:23
         * @param {*} v
         */
        delDra(v) {
            let list = this.sortPdfList;
            list = list.filter((item) => {
                return v.archivesName !== item.archivesName;
            });
            this.sortPdfList = list;
        },
        /**
         * @Descripttion: 重置导出列表数据
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2022-12-29 15:25:52
         */
        resetExport() {
            this.sortPdfList = JSON.parse(JSON.stringify(this.initSortList));
        },
        /**
         * @Descripttion:预览pdf
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-06 09:48:00
         */
        previewPdf() {
            this.previewPdfList = {
                schoolId: "",
                peopleId: "",
                archivesTypeIds: [],
            };
            this.sortPdfList.map((item) => {
                this.previewPdfList.archivesTypeIds.push(item.id);
            });
            this.previewPdfList.schoolId = this.query.schoolId;
            this.previewPdfList.peopleId = this.query.row.id;
            this.exportTeacherInfoDialog = true;
        },
        /**
         * @Descripttion: 关闭教师预览弹窗
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-06 10:17:53
         */
        closeTeacherDialog() {
            this.exportTeacherInfoDialog = false;
        },
        desensitizeGet() {
            if (this.ruleForm.phone.indexOf("*") !== -1) {
                this._fet(
                    `/school/schoolTeacher/getTeacherPhone/${this.ruleForm.id}`,
                    {
                        id: this.ruleForm.id,
                    },
                ).then((res) => {
                    if (res.data.code === "200") {
                        this.$set(this.ruleForm, "phone", res.data.data);
                    } else if (res.data && res.data.msg) {
                        this.$message.error(res.data.msg);
                    }
                });
            } else {
                this.ruleForm.phone = `${this.ruleForm.phone.slice(
                    0,
                    3,
                )}*****${this.ruleForm.phone.slice(-3)}`;
            }
        },
        back() {
            this.$emit("change", "list");
        },
        async init() {
            this.loading = true;
            
            await this.getFormType();
            // 查看和编辑的时候需要校验细分权限，新增不用
            if(this.query.pageType === 'view' || this.query.pageType === 'edit') {
                this.getSegmentInfo();
            }
            await this.initAssignment()
            await this.getDictList();
            await this.getOrgList();
            await this.getAllOrgList();
            await this.getRoleList();
            await this.getTeacherInfo();
            await this.getDepartmentOrgan();
            await this.getNewRoleList();
            await this.getTemplateListEditMode()
            await this.setArrayAssign()
            this.getHeadImgByType();
            this.handlerFormRequiredFields();
            setTimeout(_=>{
                this.loading = false;
                if(!this.basicInfoEditFlag && this.moreInfoEditFlag) {
                    this.changeTabs(this.tabs[1])
                }
            })
        },
        /**
         * @Description: 初始化赋值
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-25 15:49:50
         */
         initAssignment () {
            this.ruleForm.schoolId = this.query.schoolId =
                this.$store.state.schoolId;
            this.anchorList = this.baseInfo;
         },
         setArrayAssign () {
            this.showPostList = [];
            this.showRoleList = [];

            if (this.ruleForm.powerOrganList) {
                this.ruleForm.powerOrganList = this.ruleForm.powerOrganList.map(
                    (e) => {
                        return {
                            id: e.organId,
                            name: e.organName,
                        };
                    },
                );
            };

            if (this.ruleForm.roleIdList) {
                this.newRoleList.map((item) => {
                    this.ruleForm.roleIdList.map((i) => {
                        if (i == item.id) {
                            this.showRoleList.push(item.roleName);
                        }
                    });
                });
            };

            if (this.ruleForm.post) {
                this.postList.map((item, index) => {
                    this.ruleForm.post.map((i) => {
                        if (i == item.value) {
                            this.showPostList.push(item.label);
                        }
                    });
                });
            };

            this.roleList.map((item) => {
                if (item.id == this.ruleForm.roleId) {
                    this.teacherRoleList.push(item.roleName);
                }
            });

            this.subjects.map((item) => {
                if (item.value == this.ruleForm.subject) {
                    this.subJectList.push(item.label);
                }
            });
         },
        /**
         * @Description: 获取页面表单状态
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-25 11:24:13
         */
        async getFormType () {
            if (this.query.pageType == 'add') {
                this.tabs.forEach((item) => {
                    if (item.id === 'moreInfo') {
                        this.$set(item, 'hasAuth', false)
                    }
                })
            }

            if (this.query.pageType == 'edit') {
                this.tabs.forEach((item) => {
                    this.$set(item, 'hasAuth', true)
                })
            }
        },
        async getTemplateListEditMode() {
            await this._fet("/school/schoolArchivesType/listByCondition", {
                schoolId: this.schoolId,
                archivesPersonType: '2', // 档案应用人类型 1学生档案 2教师档案
            }).then(async (res) => {
                if (res.data.code == '200') {
                    let templateList = res.data.data;
                    this.moreInfo = [];

                    if (this.query.pageType != 'stu') {
                        templateList = templateList.map((e) => {
                            e.mode = this.query.pageType;
                            return e;
                        });
                    }

                    this.templateList = templateList;
                    if(this.query.pageSource === 'userProfile') {
                        // 个人档案的编辑
                        // 更多信息中根据接口中返回的模块判断
                        let userPermissions = await this._fet("/school/schoolStudent/getStuArchives", {
                            archivesPersonType: '2', // 档案应用人类型 1学生档案 2教师档案
                            schoolId: this.schoolId,
                            id: this.query.teacherId
                        })
                        console.log("[获取个人档案权限]userPermissions", userPermissions)
                        userPermissions = userPermissions.data;
                        if(userPermissions.code == '200') {
                            userPermissions = userPermissions.data;
                            let userTemplateList = userPermissions.list;
                            this.templateList = this.templateList.filter(e => userTemplateList.findIndex(i => i.id == e.id) > -1)
                                
                            
                            // 编辑：基础信息、更多信息的编辑权限
                            this.basicInfoEditFlag = userTemplateList.findIndex(i => i.id == this.editBasicId) > -1;
                            this.moreInfoEditFlag = userTemplateList.filter(e => e.id != this.editBasicId).length > 0;
                            this.tabs[0].hasAuth = this.basicInfoEditFlag;
                            this.tabs[1].hasAuth = this.moreInfoEditFlag;
                        }

                    } else if(this.moreInfoEditFlag && !this.summaryTeacherEditArr.includes('all')) {
                        // 更多信息中的部分模块可编辑
                        console.log('this.summaryTeacherEditArr---',this.summaryTeacherEditArr,this.templateList)
                        this.templateList = this.templateList.filter(e => this.summaryTeacherEditArr.includes(e.id))
                    }
                    console.log('this.templateList---',this.templateList)
                    this.templateList.forEach((item) => {
                        let moreInfoItem = {
                            title: item.archivesName,
                            rules: '',
                            text: '',
                            finish: false,
                            key: 'J_' + item.id,
                            change: true
                        };

                        this.moreInfo.push(moreInfoItem)
                    })
                    if (this.templateList.length > 0) {
                        this.selectedTemplate = 0;
                    }
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg)
                }
            });
        },
        /**
         * @Descripttion: 获取部门树形列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 15:25:27
         */
        async getDepartmentOrgan() {
            let res = await this._fet(
                "/school/schoolOrgan/getDepartmentOrgan",
                {
                    schoolId: this.query.schoolId,
                },
            );
            if (res.data.code == "200") {
                this.organList = res.data.data;
            }
        },
        /**
         * @Descripttion: 获取角色列表
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 15:53:16
         */
        async getNewRoleList() {
            let res = await this._fet("/school/schoolRole/listByCondition", {
                schoolId: this.query.schoolId,
            });
            if (res.data.code == "200") {
                this.newRoleList = res.data.data;
            }
        },
        /**
         * @Descripttion: 部门数据权限修改
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: lyx
         * @Date: 2023-01-04 11:15:20
         */
        handlerPowerOrganListChange(value) {
            this.ruleForm.powerOrganList.push(value);
        },
        handleCheckedCitiesChange(value) {

        },
        async getTeacherInfo() {
            // 添加时登录密码/所属角色默认值
            if (this.query.pageType === "add") {
                this.ruleForm.password = `Yxy@2022`;
                // 默认普通教师角色
                const find = this.roleList.find(
                    (i) => i.isDefaultTeacher === 1,
                );
                if (find) {
                    this.ruleForm.roleId = find.id;
                }
            }
            if (["view", "edit", "add"].includes(this.query.pageType)) {
                if (!this.teacherId && this.query.pageType == "add") {
                    return;
                }
                let str = this.teacherId ? this.teacherId : this.query.row.id;
                let r = await this._fet(
                    `/school/schoolTeacher/getTeacherInfo/${str}`,
                );
                if (r.data.code === "200") {
                    let detail = r.data.data;
                    Object.keys(detail).forEach((subKey) => {
                        this.ruleForm[subKey] = detail[subKey];
                    })
                    this.ruleForm['orgList'] = detail.deptList;
                    this.allOrgList.forEach(item => {
                        if(this.permissionOrgList.filter(i => i.id == item.id).length > 0) {
                            item.disabled = false;
                        } else {
                            item.disabled = true;
                        }
                    })
                    this.options = listToTree(this.allOrgList, {
                        parentKey: "parentOrg",
                    });
                    this.options3 = JSON.parse(JSON.stringify(this.options))
                    this.ruleForm['manageClassList'] = detail.masterClassList;
                    this.ruleForm['oldManageClassList'] = detail.masterClassList;
                    if (!this.ruleForm.roleIdList) {
                        this.ruleForm.roleIdList = [];
                    }
                    if (this.ruleForm.post && this.ruleForm.post.length > 0) {
                        this.ruleForm.post = this.ruleForm.post.split(",");
                    }
                    this.oriBasicForm = JSON.parse(
                        JSON.stringify(this.ruleForm),
                    );
                } else {
                    this.$message.error(r.data.msg);
                }
                // 编辑的时候如果有密码，不校验密码格式，保存的时候置空，并且不可编辑
                if (this.ruleForm.password) {
                    this.disabledPassword = true;
                    this.rules.password = [
                        {
                            required: true,
                            message: "请输入登录密码",
                            trigger: "blur",
                        },
                    ];
                }
            }
        },
        async getOrgList() {//带权限的
            let res = await this._fet("/school/schoolOrgan/listByCondition", {
                schoolId: this.query.schoolId,
            });
            if (res.data.code === "200") {
                const list = res.data.data.list;
                const list1 = res.data.data.list.filter(item => item.organType == 1);//带权限的
                this.permissionOrgList = list1;
                const list2 = list.filter((it) => it.organType != 1);
                this.options2 = listToTree(list2, {
                    parentKey: "parentOrg",
                });
                // if(this.options2.length == 0){
                //     // 如果班级权限为空，则设置 任教班级 未非必填
                //     this.rules['subjectClassList'][0].required  = false;
                // }
            } else {
                this.$message.error(res.data.msg);
            }
        },
        async getAllOrgList() {
            let res = await this._fet("/school/schoolOrgan/listByConditionNoAuth", {
                schoolId: this.query.schoolId,
            });
            if(res.data.code == '200') {
                let allList = res.data.data.list.filter((it) => it.organType == 1);
                this.allOrgList = allList;
                if (!this.teacherId && this.query.pageType == "add") {//添加
                    this.allOrgList.forEach(item => {
                        if(this.permissionOrgList.filter(i => i.id == item.id).length > 0) {
                            item.disabled = false;
                        } else {
                            item.disabled = true;
                        }
                    })
                    this.options = listToTree(this.allOrgList, {
                        parentKey: "parentOrg",
                    });
                    this.options3 = JSON.parse(JSON.stringify(this.options))
                }
            }
        },
        async getDictList() {
            let res = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    dictKey: "teachingPost",
                    schoolId: this.query.schoolId,
                },
            );
            if (res.data.code === "200") {
                this.postList = res.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
                // this.postList.unshift({label: "全部", value: ""});
            } else {
                this.$message.error(res.data.msg);
            }

            let res2 = await this._fet(
                "/school/schoolDictValue/listByCondition",
                {
                    schoolId: this.query.schoolId,
                    dictKey: "teachingSubjects",
                },
            );
            if (res2.data.code === "200") {
                this.subjects = res2.data.data.map((item) => ({
                    label: item.name,
                    value: item.id,
                }));
            } else {
                this.$message.error(res2.data.msg);
            }
        },
        /**
         * @Description 获取角色下拉数据
         * @DoWhat 获取角色下拉数据
         * @UseScenarios 选择所属角色
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/8 17:04
         **/
        async getRoleList() {
            await this._fet("/school/schoolRole/getRoleList", {
                schoolId: this.schoolId,
            }).then((res) => {
                if (res.data.code === "200") {
                    this.roleList = res.data.data;
                } else if (res.data && res.data.msg) {
                    this.$message.error(res.data.msg);
                }
            });
        },

        postName(postName) {
            this.ruleForm.postName = postName;
        },
        submitForm(formName) {
            this.$refs.summaryTeacherForm.submitForm();
        },
        addNewSector() {
            // return;
            let form = JSON.parse(JSON.stringify(this.ruleForm));
            if (!form.id) {
                delete form.id;
            }
            if (form.post) {
                form.post = form.post.toString();
            }
            // 获取科目名称
            let find = this.subjects.find((i) => i.value === form.subject);
            if (find) {
                form.subjectName = find.label;
            }
            if (form.isSubjectTeacher === "2") {
                form.subjectClassList = [];
                form.subjectName = "";
                form.subject = "";
            }
            if (form.isHeadmaster === "2") {
                form.manageClassList = [];
            }
            if (this.disabledPassword) {
                form.password = "";
            }
            if (form.entryTime) {
                form.entryTime = form.entryTime + " " + "00:00:00";
            } else {
                form.entryTime = '';
            }
            this.$delete(form,"headImgPass")
            this._fet("/school/schoolTeacher/save", form).then((res) => {
                if (res.data.code == "200") {
                    this.oriBasicForm = res.data.data;
                    this.teacherId = this.oriBasicForm.id;
                    this.archivesCompleteness =
                        res.data.data.archivesCompleteness;
                    this.$message.success("成功");
                    // 修改教师信息之后，重新调用getUserInfo，刷新保存的个人信息
                    this.$store.dispatch("setUserRouters");
                    // this.$emit("change", "list");
                    setTimeout(() => {
                        this.changeTabsEditType()
                    }, 100)
                } else {
                    this.$message.error(res.data.msg);
                }
            });
        },
        // 展示或隐藏档案表单
        showStowDetailForm() {
            this.detailFormShow = !this.detailFormShow;
        },

        /**
         * @Description 修改手机号
         * @DoWhat 登录名默认为手机号
         * @UseScenarios 修改手机号
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:02
         **/
        changePhone() {
            this.ruleForm.loginName = this.ruleForm.phone;
        },
        /**
         * @Description 手机号失去焦点
         * @DoWhat 查询账号信息及权限
         * @UseScenarios 手机号失去焦点
         * @Attention 无
         * @Author WGS
         * @Date 2023/03/20 14:22
         **/
        handlePhoneBlur() {
            this.getUserByLoginName();
        },
        /**
         * @Description 登录账号失去焦点
         * @DoWhat 查询账号信息及权限
         * @UseScenarios 登录账号失去焦点
         * @Attention 无
         * @Author WGS
         * @Date 2023/03/20 14:24
         **/
        handleLoginNameBlur() {
            this.getUserByLoginName();
        },
        /**
         * @Description 查询账号信息及权限
         * @DoWhat 查询账号信息及权限
         * @UseScenarios 通过登录账号查询账号信息及权限
         * @Attention 无
         * @Author WGS
         * @Date 2023/03/20 14:26
         **/
        getUserByLoginName() {
            if (!this.ruleForm.loginName) return;
            this._fet("/school/schoolUser/getUserByLoginName", {
                schoolId: this.$store.state.schoolId,
                loginName: this.ruleForm.loginName,
            }).then((res) => {
                if (
                    res.data.code == "200" &&
                    res.data.data &&
                    res.data.data.id
                ) {
                    // 用户名
                    this.ruleForm.userName = res.data.data.userName;
                    // 登录密码
                    this.ruleForm.password = res.data.data.password;
                    // 登录密码是否可编辑
                    this.disabledPassword = true;
                    // 部门数据权限
                    let list = res.data.data.powerList
                        ? res.data.data.powerList
                        : [];

                    if (list.length > 0) {
                        this.ruleForm.powerOrganList = list.map((e) => {
                            return {
                                id: e.organId,
                                name: e.organName,
                            };
                        });
                    }
                    // 角色组
                    this.ruleForm.roleIdList = res.data.data.roleList.map(
                        (e) => e.id,
                    );
                } else {
                    this.ruleForm.userName = "";
                    this.ruleForm.password = "";
                    this.disabledPassword = false;
                    this.ruleForm.powerOrganList = [];
                    this.ruleForm.roleIdList = [];
                    if (res.data.code != "200" && res.data.msg)
                        this.$message.error(res.data.msg);
                }
            });
        },
        /**
         * @Description 上传头像成功回调
         * @DoWhat 上传头像成功回调
         * @UseScenarios 上传头像成功回调
         * @Attention 无
         * @Author 韩熙昊
         * @Date 2022/11/10 15:05
         **/
        handleAvatarSuccess(file) {
            this.ruleForm.headImg = file;
        },
        /**
         * @Description: 返回一级页面
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-18 10:43:03
         */
        handlerGlobalType() {
            this.$emit("change", "list");
        },
        /**
         * @Description: 全局表单必填项
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-19 11:08:55
         */
        handlerFormRequiredFields() {
            const getRules = getNewRules();
            this.formRules.positionInfo =
                getRules[
                    this.ruleForm.isSubjectTeacher + this.ruleForm.isHeadmaster
                ]();
            this.baseInfo = formRequiredFields(
                this.baseInfo,
                this.formRules,
                this.ruleForm,
            );

            if(this.query.pageType == 'edit' && !this.basicInfoEditFlag && this.moreInfoEditFlag){
                this.anchorList = this.moreInfo;
            } else {
                this.anchorList = this.baseInfo;
            }
        },
        /**
         * @Description: 切换tabs
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-25 11:01:52
         */
        changeTabs (item) {
            if (this.loading) return;
            if (item.hasAuth) {
                this.tabs.forEach((subItem) => {
                    if (subItem.id == item.id) {
                        this.$set(subItem, 'sel', true)
                    } else {
                        this.$set(subItem, 'sel', false)
                    }
                })
                if (item.id == 'baseInfo') {
                    this.anchorList = this.baseInfo;
                };

                if (item.id == 'moreInfo') {
                    this.anchorList = this.moreInfo;
                    this.$set(this.moreInfo[this.selectedTemplate], 'active', true)
                };

            } else {
                this.tabs[0].hasAuth = this.basicInfoEditFlag;
                this.tabs[1].hasAuth = this.moreInfoEditFlag;
                if(item.id == 'baseInfo' && !this.basicInfoEditFlag) {
                    this.$message.warning('暂无编辑基础信息的权限～')
                } else if(item.id == 'moreInfo' && !this.moreInfoEditFlag) {
                    this.$message.warning('暂无编辑更多信息的权限～')
                }  else {
                    this.$message.warning('请保存基础信息～')
                }
            }
        },
        /**
         * @Description: 修改表单更多信息编辑状态
         * @DoWhat: 提交完成基础信息后，更多信息可填写
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-25 11:42:37
         */
         changeTabsEditType () {
            this.tabs.forEach((item) => {
                this.$set(item, 'hasAuth', true)
            })
         },
        /**
         * @Description: 切换锚点
         * @DoWhat: 如果change为true则认为是更多信息
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-27 10:46:10
         */
         handlerFormScroll (data) {
            if(data.change) {
                this.$set(this.moreInfo[this.selectedTemplate], 'active', false)
                this.selectedTemplate = this.moreInfo.findIndex((item) => {
                    return item.key == data.key
                })
                this.$set(this.moreInfo[this.selectedTemplate], 'active', true)
            }
         },
        /**
         * @Description: 更多信息表单必填项教研
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: 武东超
         * @Date: 2023-07-27 14:55:53
         */
         getFormRules: debounce(function (data){
            let formList = this.getAllFormItem(data),
                str = Object.prototype.toString,
                arrType = '[object Array]',
                strType = 'string',
                num = 0,
                requiredLen = formList.filter(item => item.required).length;
                formList.forEach((item) => {
                    if(item.required && str.call(item.value) === arrType && item.value.length > 0) {
                        num++;
                    }

                    if (item.required && typeof item.value === strType && item.value) {
                        num++;
                    }
                })
                if (requiredLen - num > 0) {
                    this.moreInfo[this.selectedTemplate].text = `${requiredLen - num}必填项未填`
                    this.moreInfo[this.selectedTemplate].finish = false;
                } else if (requiredLen == 0) {
                    this.moreInfo[this.selectedTemplate].text = '';
                    this.moreInfo[this.selectedTemplate].finish = false;
                }else {
                    this.moreInfo[this.selectedTemplate].finish = true;
                }
         }, 200),
         getAllFormItem (data) {
            let formList = [];
            data.forEach((item) => {
                if (item.list && item.list.length > 0) {
                    formList = formList.concat(...this.getAllFormItem(item.list))
                } else {
                    formList.push(item)
                }
            })
            return formList;
         },
         setHeadImgInfo(obj) {
            this.headImgInfo = obj;
         },
         async getHeadImgByType() {
            if(this.query.pageType == 'add') {
                return
            }
            let obj = {
                personType: 'teacher',
                personId: this.teacherId ? this.teacherId : this.query.row.id
            }
            let res = await this._fet('/school/common/getHeadImgByType', obj)
            if(res.data.code == '200') {
                let data = res.data.data
                this.headImgInfo.auditState = data.headImgPass || "";
                this.headImgInfo.tip = data.headImgMessage || "";
            }
        }
    },
    watch: {
        ruleForm: {
            handler() {
                this.handlerFormRequiredFields();
            },
            deep: true,
        },
        'query': {
            handler (newVal) {
            },
            deep: true
        }
    },
};
</script>

<style lang="scss" scoped>
.basic-title {
    font-size: 16px;
}
.line-ti {
    line-height: 40px;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #595959;
}

.page {
    padding: 16px;
    height: calc(100vh - 140px);
    // display: flex;
    // flex-direction: column;
    overflow-y: auto;
}

.exportDialog {
    margin-top: 10px;

    .exportDialog-title {
        display: flex;
        align-items: center;

        .exportDialog-text {
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #363a40;
            margin-right: 16px;
        }

        .exportDialog-reset {
            cursor: pointer;
            width: 72px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            background: #e8f1fc;
            border-radius: 4px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #3C7FFF;
        }
    }

    .exportDialog-content {
        width: 544px;
        background: #ffffff;
        border: 1px solid #edeff2;
        border-radius: 2px;
        margin-top: 20px;

        .exportDialog-content-title {
            display: flex;
            line-height: 40px;
            background: #f5f7fa;

            .exportDialog-content-sort {
                width: 80px;
                border-right: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
            }
        }
        .exportDialog-content-title-sort {
            display: flex;
            line-height: 40px;
            .exportDialog-content-sort-title {
                width: 80px;
                border-right: 1px solid #edeff2;
                border-bottom: 1px solid #edeff2;
                text-align: center;
            }

            .exportDialog-content-name-text {
                flex: 1;
                padding-left: 20px;
                box-sizing: border-box;
                border-bottom: 1px solid #edeff2;
            }
        }
    }

    .exportDialog-list {
        display: flex;
        background-color: #fff;
        height: 40px;
        line-height: 40px;

        .exportDialog-sort {
            width: 80px;
            height: 40px;
            line-height: 40px;
            border-right: 1px solid #edeff2;
            border-bottom: 1px solid #edeff2;
            text-align: center;

            &:last-child {
                border-bottom: 0;
            }
        }

        .exportDialog-name {
            flex: 1;
            height: 40px;
            line-height: 40px;
            display: flex;
            border-bottom: 1px solid #edeff2;
            position: relative;

            .exportDialog-name-info {
                margin-left: 20px;
            }

            .exportDialog-icon {
                position: absolute;
                right: 20px;
                top: 14px;
                display: flex;

                .move-icon {
                    width: 14px;
                    height: 14px;
                    margin-right: 26px;
                    display: block;
                    cursor: pointer;
                }

                .del-icon {
                    cursor: pointer;
                    width: 12px;
                    height: 12px;
                    display: block;
                }
            }
        }
    }

    .exportDialog-info {
        margin-top: 20px;
        height: 14px;
        line-height: 14px;
        display: flex;

        .dialog-info-icon {
            width: 13px;
            height: 13px;
        }

        .dialog-info-text {
            margin-left: 5px;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #f56c6c;
        }
    }

    .dialog-footer {
        display: flex;
        // margin: 0 auto;
        margin-top: 26px;

        .dialog-cancel,
        .dialog-submit {
            width: 120px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border: 1px solid #3C7FFF;
            border-radius: 4px;
            color: #3C7FFF;
            cursor: pointer;
        }

        .dialog-submit {
            background: #3C7FFF;
            color: #ffffff;
            margin: 0 24px 0 140px;
        }
    }
}

.archivesCompleteness {
    width: 100%;
    height: 40px;
    line-height: 40px;
    // border: 1px solid black;
    background: #edf4fa;
    margin-top: 32px;
    display: flex;
    align-items: center;

    .archivesCompleteness-icon {
        display: inline-block;
        margin-left: 12px;
        width: 13px;
        height: 8px;
    }

    .archivesCompleteness-title {
        font-size: 12px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #6c84a6;
        margin: 0 10px;
    }

    .archivesCompleteness-value {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #3C7FFF;
    }
}
.newRoleList {
    width: 100% !important;
}

// 公共-有分割线的标题
::v-deep .line-title {
    text-align: center;
    display: flex;
    flex-direction: row;
}

::v-deep .line-title:before {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

::v-deep .line-title:after {
    content: "";
    flex: 1 1;
    width: 200px;
    margin: auto 20px;
    background-origin: padding-box, border-box;
    background-image: linear-gradient(135deg, #ffffff, #ffffff),
        linear-gradient(-90deg, rgba(255, 255, 255, 0) 0%, #e6e6e6 100%);
    border-bottom: 1px solid;
    border-image: linear-gradient(
        -90deg,
        rgba(255, 255, 255, 0) 0%,
        #e6e6e6 100%
    );
}

// 公共-按钮
.btn {
    width: 88px;
    height: 36px;
    border-radius: 2px;
    font-size: 14px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
}

.btn-reset {
    background: #ebf1f7;
    color: #737373;
}

.btn-confirm {
    background: #3C7FFF;
    color: #ffffff;
}

// 基础信息
.basic {
    background-color: #ffffff;
    border-radius: 6px;
    padding: 20px;
}

// 基础信息-头部
.basic-header,
.basic-header-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    .basic-header-flag {
        width: 1px;
        height: 16px;
        background: #bfbfbf;
        margin: 0 16px 0 12px;
    }

    .back {
        font-size: 14px;
        margin-left: 0;
        color: #3C7FFF;
    }

    .basic-title {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #595959;
        flex: 1;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .exportBtn {
        margin-left: 0;
    }
}

.basic-header-view {
    width: calc(100% - 20px);
    position: absolute;
    top: 0px;
    left: 0px;
    height: 52px;
    display: flex;
    align-items: center;
    padding-left: 20px;
    border-bottom: 1px solid #E8E8E8;
}

// 基础信息-表单
.basic-form,
.basic-form-view {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 56px 10px 40px 40px;

    .baseTitle {
        width: 100%;
        text-align: left;
        // border-bottom: 1px solid black;
    }

    .baseMain {
        display: flex;
    }

    .lt {
        /deep/.el-upload {
            text-align: left;
        }

        .upload-img {
            width: 104px;
            height: 104px;
            border-radius: 6px;
        }

        .upload-text {
            font-size: 12px;
            color: #bfbfbf;
            line-height: 18px;
            margin-top: 16px;
            white-space: nowrap;
        }
    }

    .rt {
        width: 75%;
        display: flex;
        flex-wrap: wrap;

        .el-form-item {
            padding-left: 40px;
            box-sizing: border-box;
            width: 50%;

            /deep/ .el-form-item__label {
                padding-right: 0;
            }

            /deep/ .el-select {
                width: 100%;
            }
        }

        .div-divider {
            height: 1px;
            width: 100%;
            margin: 10px 0 24px 40px;
            background: #edf0f2;
        }
    }

    .tips {
        font-size: 12px;
        display: block;
        margin-top: -5px;
        height: 25px;
        color: #909399;
        white-space: nowrap;
    }
}

.basic-form-view {
    display: block;
    padding: 0;
    margin-top: 50px;

    // border: 1px solid black;
    .rt {
        width: 100%;
        display: block;
    }

    .lt {
        height: 124px;
        border: 1px solid #919599;
        border-left: 1px solid rgba(255, 255, 255, 0);

        .upload-img {
            display: block;
            height: 104px;
            width: 104px;
            border-radius: 8px;
            margin: 10px auto 0;
        }
    }
}

.tac {
    text-align: center;
}

// 展示或收起按钮
.show-stow-btn {
    margin-top: 10px;
    height: 48px;
    background: #f7fafc;
    border-radius: 6px 6px 0px 0px;
    line-height: 48px;
    text-align: center;
    font-size: 16px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #3C7FFF;
    cursor: pointer;

    img {
        width: 12px;
        height: 8px;
        margin-left: 8px;
        margin-bottom: 2px;
    }
}

// 组件组
::v-deep .comps-group-div {
    margin-top: 22px;

    .comps-group-item {
        padding: 0;
    }
}

// 更多信息表
.detail-form {
    background-color: #ffffff;
    border-radius: 0px 0px 6px 6px;
    // flex: 1;
}

.baseCol {
    border: 1px solid #919599;
    line-height: 40px;
    text-align: center;
    font-size: 14px;
    color: #363b40;
    min-height: 42px;
    padding: 0 12px;
    box-sizing: border-box;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
}

.refer {
    position: absolute;
    top: 0;
    left: 302px;
    width: 20px;
    font-size: 14px;
    color: #363b40;
    // border: 1px solid black;
}

.baseCol-first {
    border-left: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-secondTitle {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-second {
    border-left: 1px solid rgba(255, 255, 255, 0);
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-right {
    border-right: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-bottom {
    border-bottom: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-top {
    border-top: 1px solid rgba(255, 255, 255, 0);
}

.baseCol-img {
    border-left: 1px solid rgba(255, 255, 255, 0);
    height: 126px;
    line-height: 126px;
    text-align: center;
}

.baseCol-checked {
    background-color: #edf4fa;
}

.view-title {
    width: 100%;
    height: 14px;
    line-height: 14px;
    display: flex;
    margin: 20px 0 16px;

    .view-flag {
        width: 3px;
        height: 14px;
        background-color: #3C7FFF;
        margin-right: 8px;
    }

    .title {
        font-size: 16px;
        font-weight: bold;
    }
}

.post-item {
    margin: 0 5px;
}

.page-view {
    .basic {
        position: relative;
        margin: 0 auto;
        width: 1000px !important;
    }

    ::v-deep .stu-info-fill-main {
        padding: 0 0 24px 0 !important;
    }

    ::v-deep .el-form-item {
        margin-bottom: 0;
        border: 1px solid black;

        .explainText {
            display: none;
        }

        .el-checkbox-group {
            // box-sizing: border-box;
            overflow-y: scroll;
        }

        .el-radio-group {
            width: 100%;
            // padding-top: 10px;
            line-height: 47px !important;
            height: 36px;
            overflow-y: scroll;
        }

        .el-form-item__label {
            text-align-last: justify; // 最后一行两端对齐
            text-align: justify; // 两端对齐
            padding: 0 12px;
            width: 166px !important;
            // box-sizing: border-box;
            // background-color:#edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid black;
            // height: 36px;
            min-height: 36px;
            position: relative;
            margin-left: 166px !important;

            .explain {
                text-align: center !important;
                font-size: 14px !important;
                color: #363b40 !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .form-value-show-only,
            .el-radio,
            .el-checkbox-group,
            .explain {
                padding-left: 15px;
            }

            .el-checkbox-group {
                height: 36px;
            }

            .el-radio-group {
                height: 36px;
            }

            .upload-demo {
                padding-left: 5px;
                display: flex;
                align-items: center;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }

            .avatar-uploader {
                padding: 5px 10px;
            }

            .input-div {
                position: absolute;
                height: 100%;

                .input {
                    .el-input__inner {
                        font-size: 14px;
                        color: #363b40;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        height: 100%;
                        text-align: center;
                    }
                }
            }
        }
    }

    /deep/ .el-table--border,
    .el-table--group {
        border: 1px solid #919599 !important;
    }

    /deep/ .el-table th.el-table__cell {
        color: #363b40 !important;
        font-size: 14px;
        font-weight: 400;
        background-color: #edf4fa !important;
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
        }
    }

    /deep/ .el-table__body td.el-table__cell {
        border: 1px solid #919599 !important;
        border-top: 1px solid rgba(255, 255, 255, 0) !important;
        font-size: 14px;
        color: #363b40;

        &:nth-child(n) {
            border-left: 1px solid rgba(255, 255, 255, 0) !important;
            // border-bottom: 1px solid #919599 !important;
        }
    }

    /deep/ .el-form-item {
        border: 1px solid #919599 !important;
        height: inherit !important;

        .el-input.is-disabled .el-input__inner {
            background-color: white !important;
            text-align: center;
        }

        .el-textarea.is-disabled .el-textarea__inner {
            background-color: white !important;
            font-size: 14px;
            color: 363b40;
        }

        .el-form-item__label {
            text-align: center;
            text-align-last: unset;
            font-size: 14px;
            color: #363b40;
            background-color: #edf4fa;
        }

        .el-form-item__content {
            border-left: 1px solid #919599 !important;
        }

        .avatar-uploader-icon {
            width: 148px !important;
            height: 148px !important;
            line-height: 148px !important;
        }

        .avatar-uploader {
            margin-top: 21px !important;
            // overflow: unset !important;
        }

        .el-upload {
            cursor: not-allowed !important;
        }

        // .el-input__inner{
        //   background-color: white !important;
        // }
    }
}
</style>
<style lang="scss" scoped>
    .common-form {
        .tab-wrapper {
            height: 100%;
            box-sizing: border-box;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            .tab-item {
                height: 48px;
                line-height: 48px;
                position: relative;
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #606C80;
                padding: 0 17px;
                cursor: pointer;
                .sel-line {
                    display: block;
                    position: absolute;
                    bottom: 0;
                    left: calc(50% - 15px);
                    width: 30px;
                    height: 3px;
                    background: #3C7FFF;
                    border-radius: 2px;
                }
            }
            .tab-item.current {
                font-size: 15px;
                font-family: Microsoft YaHei;
                font-weight: bold;
                color: #2B2E33;
            }
        }
    }

</style>
<style lang="scss">
.common-form-wrapper {
    position: relative;
}
.page-view .el-input__inner::placeholder {
    color: white !important;
}

.page-view .el-textarea__inner::placeholder {
    color: white !important;
}
</style>
